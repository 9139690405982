module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Testosterone Replacement Therapy (TRT) by Men’s Health Clinic","description":"Testosterone Replacement Therapy (TRT) for optimal men’s health by the global leader Men’s Health Clinic. Get treated for low testosterone by the experts at MHC.","openGraph":{"type":"website","url":"https://painclinicaustralia.com.au/","title":"Testosterone Replacement Therapy (TRT) by Men’s Health Clinic","description":"Testosterone Replacement Therapy (TRT) for optimal men’s health by the global leader Men’s Health Clinic. Get treated for low testosterone by the experts at MHC."},"twitter":{"cardType":"summary_large_image","url":"https://painclinicaustralia.com.au/"},"metaTags":[{"name":"keywords","content":"Testosterone, Low testosterone, Low T, Testosterone deficiency, Testosterone clinic, Testosterone therapy, Testosterone replacement, Testosterone replacement therapy, TRT, Men’s clinic, Men’s Health Clinic, Men’s health expert, Doctor for men, Testosterone expert, Testosterone supplements, Erectile dysfunction, Premature ejaculation, Ejaculating too quickly, Improve sexual performance, Increase muscle mass, Last longer, Clinic for men, ED, PE, Erection problems, Depression in Men,  Sexual health, Sexual problems, Sexual performance,  Better sexual performance, Increase strength, Increase erection strength, Improve mood"},{"name":"robots","content":"index, follow"},{"name":"language","content":"English"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#000000"}],"languageAlternates":[{"hrefLang":"x-default","href":"https://painclinicaustralia.com.au/"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PDHP6M7","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://painclinicaustralia.com.au/","noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Testosterone Replacement Therapy (TRT) by Men’s Health Clinic","short_name":"TRT by MHC","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/favicon/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"12d3a5769ff3e8fc2d9af2832aa108ff"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
